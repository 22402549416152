@font-face {
  font-family: AileronBold;
  src: url(./fonts/aileron-bold.woff2);
}

@font-face {
  font-family: AileronRegular;
  src: url(./fonts/aileron-regular.woff2);
}

@font-face {
  font-family: AileronSemiBold;
  src: url(./fonts/aileron-semibold.woff2);
}

@font-face {
  font-family: FreightBold;
  src: url(./fonts/freight_big_pro_bold.woff2);
}

@font-face {
  font-family: FreightLight;
  src: url(./fonts/freight_big_pro_light.woff2);
}

@font-face {
  font-family: FuturaNewBook;
  src: url(./fonts/futuranewbook-reg.woff2);
}

@font-face {
  font-family: FuturaNewDemi;
  src: url(./fonts/futuranewdemi-reg.woff2);
}

@font-face {
  font-family: FuturaNewHeavy;
  src: url(./fonts/futuranewheavy-reg.woff2);
}

@font-face {
  font-family: FuturaNewMedium;
  src: url(./fonts/futuranewmedium-reg.woff2);
}

* {
  scroll-behavior: smooth;
}

:root {
  --background: #fff;
  --primary-color: #000;
  --accent-color: #fe5fa1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
img {
  user-select: none !important;
}

body {
  overflow-x: hidden !important;
  scroll-behavior: smooth;
  background: var(--background);
  color: var(--primary-color);
  font-family: AileronRegular;
}

.hide-800 {
  display: flex;
}

/* NAVIGATION */

.menu-button {
  position: fixed;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 30px;
  height: 30px;
  top: 2rem;
  right: 20px;
  z-index: 110;
  transition: 0.3s all ease-in-out;
}

.menu-button .line-top {
  position: relative;
  height: 2px;
  background: #000;
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.menu-button .line-bottom {
  position: relative;
  height: 2px;
  background: #000;
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.close-button .line-top {
  transform: rotate(45deg) translateY(2px);
}

.close-button .line-bottom {
  transform: rotate(-45deg) translateY(-1px);
}

.close-button {
  gap: 0px;
  transition: 0.3s all ease-in-out;
  z-index: 110;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.8px);
  -webkit-backdrop-filter: blur(12.8px);
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.65);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.1px);
  -webkit-backdrop-filter: blur(10.1px);
  z-index: -1;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}

.mobile-nav-inner {
  padding-top: 100px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
}

.divider {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.075);
}

.mobile-nav a {
  color: #fff8ff;
  font-size: 20px;
  font-weight: 800;
}

.open-menu {
  opacity: 1;
  z-index: 100;
}

.social-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.social-mobile img {
  width: 20px;
}

nav {
  width: 100%;
  background: transparent;
  position: fixed;
  top: 0px;
  z-index: 100;
  left: 0;
  right: 0;
  transition: 0.3s all ease-in-out;
}

.activenav {
  background: rgba(255, 255, 255, 1);
}

.nav-wrap {
  width: 1000px;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  background: transparent;
  position: relative;

  gap: 40px;
}

nav a {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--accent-color);
  font-weight: 800;
  font-family: AileronRegular;
  text-decoration: none;
  margin: 0 16px;
  white-space: nowrap;
}

nav a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--accent-color-3);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

nav a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.noanim:hover::after {
  transform: none !important;
}

.noanim:after {
  width: 0;
  height: 0;
}

.mainlogo {
  width: 80px;
  height: auto;
  z-index: 100;
  border-radius: 10px;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttoncall {
  position: absolute;
  right: 20px;
  top: 20px;
}

@media (max-width: 1350px) {
  .buttoncall {
    right: 0px;
  }

  .nav-wrap {
    max-width: 800px;
  }
}

@media (max-width: 1180px) {
  .nav-wrap {
    max-width: 700px;
    justify-content: center;
    gap: 20px;
  }
}

.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 40%;
}

@media (max-width: 1090px) {
  .menu-button {
    display: flex;
  }

  .mobile-nav a {
    text-decoration: none !important;
  }

  .mobile-nav a::after {
    display: none;
  }

  nav {
    top: 0px;
    padding-inline: 5px;
  }

  .hide-800 {
    display: none;
  }
}

/* NAVIGATION END */

.home-top {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 40px;
  padding-top: 90px;
  background: url(./banner-image.jpg);
  filter: brightness(1.05);
  position: relative;
  background-size: cover;
  background-position: center;
}

.home-top::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 50vh;
  background: linear-gradient(
    to bottom,
    rgb(0, 0, 0) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.7;
}

.banner-content {
  max-width: 612px;
  margin: auto;
  position: relative;
  text-align: center;
  color: white;
}

.banner-content h4 {
  font-size: 30px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: FuturaNewBook;
  font-weight: 100;
  margin: 0 0 12px;
}

.banner-content h1 {
  font-size: 150px;
  font-weight: 300;
  font-family: FreightLight;
  margin: -48px 0 0;
}

.greybg {
  background: #f2f2f2;
}

.darkbg {
  background: #18191b;
}

.partners {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.partners img {
  margin: 20px 30px;
}

.banner-content p {
  font-size: 22px;
  line-height: 30px;
  font-family: AileronRegular;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  margin: 0;
}

.label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 5px;
  margin-bottom: 16px;
  color: #162142;
}

.label hr {
  width: 50px;
  height: 1px;
  border: 0;
  background: var(--accent-color);
  margin: 0;
}

.scroll-arrow {
  margin-top: 20px;
  animation: arrowanimation 1.4s infinite linear;
}

@keyframes arrowanimation {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.smallermobi {
  background: var(--accent-color);
  color: black;
  opacity: 1;
}

.title h1 {
  font-weight: 700;
  font-size: 36px;
  color: #191e25;
}

.title h2 {
  font-size: 24px;
  color: #162142;
  font-weight: 700;
  margin: 0;
}

.title p {
  font-size: 18px;
  max-width: 70%;
  text-align: left;
  margin: 24px 0 32px;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  row-gap: 50px;
  align-items: flex-start;
}
@media (max-width: 1370px) {
  .cards {
    flex-wrap: wrap;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  max-width: 250px;
  min-width: 220px;
}

.card h3 {
  color: #162142;
  font-size: 20px;
  margin-block: 15px;
  color: white;
  font-family: AileronRegular;
}

.card p {
  font-size: 16px;
  text-align: left;
  margin-block: 5px;
  color: white;
  font-family: AileronRegular;
  text-align: center;
}

.card img,
.card svg {
  width: 70px;
  filter: invert(1);
}
.notinvert {
  filter: invert(0) !important;
  transform: rotateY(180deg) scale(1.2);
}

.functions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  width: 100%;
}

.function-text h3 {
  font-size: 28px;
  color: #16192a;
  text-align: left;
  font-weight: 700;
}

.function-text p {
  max-width: 410px;
  text-align: left;
  font-size: 16px;
  line-height: 30px;
  color: var(--primary-color);
}

.function {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;
  align-items: center;
  width: 100%;
}

.function:nth-of-type(even) {
  flex-direction: row-reverse;
}

.why-us-wrapper {
  background: var(--accent-color);
  margin-top: 150px;
}

.why-us-wrapper .project {
  padding-top: 0 !important;
}

.why-us-trust {
  width: 100%;
  margin-top: -140px;
  height: 278px;
  background: #0d2b3f;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.why-us-trust h3 {
  color: white;
  font-size: 20px;
  max-width: 80%;
  text-align: center;
}

.why-us-stats {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 50px;
}

.why-us-stats-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.why-us-stats-item h3 {
  font-size: 52px;
  font-weight: 900;
  color: white;
  margin: 0;
}

.why-us-stats-item h6 {
  font-size: 20px;
  font-weight: 700;
  color: white;
  margin: 0;
}

.swiper {
  height: 400px;
  margin-left: calc((100vw - 1200px) / 2) !important;
}

@media (max-width: 1480px) {
  .swiper {
    margin-left: 100px !important;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  width: 500px !important;
}

.swiper-slide h2 {
  font-size: 32px;
  color: #162142;
  margin: 0;
  text-align: left;
}

.swiper-line-wrap {
  position: relative;
}

.line {
  position: absolute;
  top: 74px;
  width: 100vw;
  left: 0;
  height: 1px;
  background: var(--primary-color);
  opacity: 0.5;
}

.swiper-slide .dot {
  background: #4897e0;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 20px;
  transition: 0.2s all ease-in-out;
}

.swiper-slide h4 {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
}

.swiper-slide p {
  font-size: 14px;
  text-align: left;
  color: var(--primary-color);
  opacity: 0.6;
  line-height: 25px;
}

.nopadtop {
  padding-top: 0 !important;
}

.prev,
.next {
  padding: 20px;
  padding-block: 14px;
  background: var(--accent-color-3);
  border-radius: 12px;
  transition: 0.2s all ease-in-out;
}

.prev:hover,
.next:hover {
  background: var(--accent-color);
  cursor: pointer;
}

.swiper-slide:hover {
  cursor: pointer;
}

.swiper-slide:hover h2 {
  color: var(--accent-color);
}

.dot:after {
  content: "";
  position: absolute;
  background-color: #4897e0;
  width: 0px;
  height: 24px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.15;
  transition: 0.2s all ease-in-out;
}

.dot:before {
  content: "";
  position: absolute;
  background-color: #4897e0;
  width: 0px;
  height: 40px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
  opacity: 0.07;
  transition: 0.2s all ease-in-out;
}

.swiper-slide:hover .dot::after {
  width: 24px;
}

.swiper-slide:hover .dot::before {
  width: 40px;
}

.nav-swiper {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.nopad {
  padding: 0 !important;
}

.whitepaper-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}

.early-access-wrap {
  background: #0d2b3f;
}

.early-access-wrap .title h1 {
  color: white;
}

.early-access-wrap .title p {
  color: white;
  opacity: 0.7;
}

.early-access-wrap .project {
  flex-direction: row;
  align-items: center;
}

.early-input {
  width: 300px;
  height: 50px;
  border-radius: 12px;
  border: none;
  padding: 0 20px;
  font-size: 16px;
  color: var(--primary-color);
  border: 1px solid #0d2b3f;
  transition: 0.2s all ease-in-out;
}

.early-access-wrap .applynow {
  font-size: 18px;
  padding: 10px 32px;
}

.early-access-wrap .applynow:hover {
  font-size: 18px;
  padding: 10px 32px;
  color: white;
}

footer h3 {
  font-size: 18px;
  font-weight: 700;
  color: #191e25;
  margin: 0;
  font-family: AileronRegular;
}

.socials {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
}

.socials img {
  width: 20px;
  height: 20px;
  background: var(--accent-color-3);
  padding: 14px;
  border-radius: 10px;
  transition: 0.2s all ease-in-out;
}

.socials img:hover {
  background: var(--accent-color);
}

footer h6 {
  font-size: 12px;
  color: #191e25;
  opacity: 1;
  margin: 0;
  font-family: AileronRegular;
}

footer p {
  min-width: 300px;
}

.row {
  display: flex;
  justify-content: space-between;
}

footer .project {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 0;
}

footer .mainlogo {
  width: 200px;
}

.quick-links-wrap {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
  width: 100%;
  justify-content: space-between;
}

.quick-links {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
  width: 100%;
  justify-content: space-between;
  padding-top: 30px;
}
@media (max-width: 800px) {
  .quick-links {
    flex-direction: column;
    justify-content: center;
  }
}
.quick-links a {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--accent-color);
  font-weight: 800;
  font-family: AileronRegular;
  text-decoration: none;
  margin: 0;
  white-space: nowrap;
}

.contact-form {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 45%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  max-width: 550px;
}

.apply-page .contact-form {
  max-width: 550px;
  background: #dfdfdf34;
  border-radius: 5px;
  box-shadow: 1px 1px 15px 0px #0000003b;
}

.contact-form .title {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.apply-page .applynow,
.contact-page .applynow {
  margin-top: 0;
}

.apply-page .contact-form .title {
  font-size: 38px;
  font-family: FuturaNewBook;
}

.form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.group {
  position: relative;
  margin-bottom: 20px;
}

.form .group label {
  font-size: 15px;
  color: #000;
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.apply-page label {
  background-color: #f8f8f8;
}

.form .group input,
.form .group textarea,
.form .group select {
  padding: 13px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  width: calc(100% - 26px);
  background-color: transparent;
  font-size: 15px;
}

.form .group select {
  width: 100%;
}

.form .group textarea {
  margin-bottom: 0;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0) !important;
}

.iti {
  width: 100% !important;
}

.form .group .full {
  width: 100%;
}

option {
  font-size: 16px;
  padding-block: 5px;
  font-family: AileronRegular;
}

.form .group input:placeholder-shown + label,
.form .group textarea:placeholder-shown + label {
  top: 10px;
  background-color: transparent;
}

.form .group input:focus,
.form .group textarea:focus {
  border-color: var(--accent-color);
}

.form .group input:focus + label,
.form .group textarea:focus + label {
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: var(--accent-color);
  font-weight: 600;
  font-size: 14px;
}

.form .group textarea {
  resize: none;
  height: 100px;
}

.project,
footer {
  padding: 50px 0;
  width: 1200px;
  max-width: 1200px;
  margin-inline: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.whatwedo {
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#services {
  scroll-margin-top: 100px;
}

.whatwedo hr,
.wordfrom hr {
  width: 100px;
  height: 4px;
  background-color: var(--accent-color);
  border: none;
  clip-path: polygon(7% 0%, 100% 0%, 93% 100%, 0% 100%);
}

.whatwedo h2,
.wordfrom h2 {
  font-size: 32px;
  line-height: 40px;
  font-family: FuturaNewBook;
  font-weight: 800;
  color: white;
  text-align: center;
  margin-top: 0;
}

.padless {
  padding: 50px 0;
}

.video-phone {
  max-width: 450px;
}
.example {
  position: relative;
  transition: 0.2s all ease;
  box-shadow: 1px 1px 10px 0px #fe5fa149;
  border: 1px solid #fe5fa12c;
}

.startout {
  flex-direction: row;
  align-items: center;
}

.shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fe5fa109;
  opacity: 0;
  transition: 0.2s all ease;
}

.example:hover .shadow {
  opacity: 1;
}

.example:hover {
  transform: scale(1.05);
  box-shadow: none;
  cursor: pointer;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.closefull {
  position: absolute;
  top: 20px;
  right: 40px;
  color: white;
  font-weight: 900;
  font-size: 25px;
  cursor: pointer;
}

.fullscreen img {
  max-width: 90%;
  max-height: 90%;
}

.teamsec .title {
  width: 100%;
  text-align: center;
}

.teamsec .title h1 {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 400;
  font-family: AileronRegular;
}

.teamsec .title h1 span {
  font-weight: 800;
  font-family: AileronBold;
}

.teamsec .title p {
  text-align: center;
  max-width: 100%;
  margin-top: 10px;
  font-family: AileronRegular;
}

.team {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  row-gap: 30px;
}

@media (max-width: 1500px) {
  .team {
    flex-wrap: wrap;
  }
}

.team-member {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1;
  max-width: 190px;
  min-width: 190px;
}
.wordfrom {
  max-width: 300px;
}

.team-image {
  background: url(./teambg.svg) center top no-repeat;
  padding: 15px;
  position: relative;
}

.team-image:after {
  content: "";
  background: #fff;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 0;
}

.team-image img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.team-member h2 {
  font-size: 18px;
  font-family: AileronBold;
  font-weight: 800;
  color: #191e25;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  text-transform: uppercase;
  white-space: nowrap;
}

.team-member h5 {
  color: var(--accent-color);
  margin: 0px;
  font-family: AileronRegular;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.padmore {
  padding: 100px 0;
}

.wordfrom h2 {
  color: #191e25;
  text-align: center;
}

.wordfrom {
  justify-content: center;
  align-items: center;
}

.word {
  width: 80%;
  background: rgba(167, 168, 168, 0.2);
  padding: 30px;
  border-left: 4px solid var(--accent-color);
  margin-inline: auto;
  margin-top: 10px;
}

.word p {
  font-family: AileronRegular;
  font-size: 18px;
  color: #191e25;
}

.pinkbg {
  background: var(--accent-color);
}

.pinkbg hr {
  background: #fff;
}
.whychooseus {
  padding-block: 60px !important;
  padding-bottom: 40px !important;
}
.whychooseus .card h3 {
  margin: 10px;
  font-size: 30px;
  font-family: AileronRegular;
}

.whychooseus .card p {
  font-family: AileronRegular;
  font-size: 18px;
}

.whychooseus h2 {
  margin-bottom: 0;
}

.whychooseus p {
  color: white;
  font-family: AileronRegular;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 50px;
  text-align: center;
}

.full-potential {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  color: #ffffff;
  background: url(./fullpotential.jpg);
}

.full-potential .project {
  padding: 125px 0;
  justify-content: center;
  align-items: flex-end;
}

.full-potential-cont {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 511px;
}

.full-potential-cont h3 {
  color: #f2f2f2;
  text-transform: uppercase;
  font-size: 30px;
  font-family: FuturaNewMedium;
  font-weight: normal;
  margin: 0 0 8px;
}

.full-potential-cont h2 {
  color: #fe5fa1;
  font-size: 80px;
  line-height: 55px;
  padding-bottom: 30px;
  margin: 0 0 20px;
  border-bottom: 1px solid #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-family: FuturaNewDemi;
  border-image: linear-gradient(
    to right,
    rgb(120, 120, 122) 0%,
    rgb(120, 120, 122) 58%,
    rgba(255, 255, 255, 0) 100%
  );
  border-bottom: 1px solid;
  border-image-slice: 1;
}

.full-potential-cont p {
  font-size: 18px;
  line-height: 24px;
  font-family: AileronRegular;
  color: #c6c7cd;
  margin: 0;
}

@media (max-width: 1480px) {
  .project,
  footer {
    width: calc(100% - 200px);
    padding: 100px 0px;
  }

  .padless {
    padding: 50px 0;
  }

  .nav-wrap {
    width: calc(100% - 200px);
  }
}

button {
  position: relative;
  padding: 13px 78px;
  border-radius: 0px;
  border: 2px solid var(--accent-color);
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  background: var(--accent-color);
  color: var(--background);
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: pointer;
  white-space: nowrap;
  font-family: AileronRegular;
}

button:hover {
  background: #ff1f7c;
  color: white;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

button:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  -moz-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}

button::before {
  content: "";
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

.rotate {
  margin-top: 40px;
  animation: rotate 15s linear infinite;
  max-width: 170px;
}

.mainlogowrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.applynow {
  margin-top: 28px;
}

.applynow-nav {
  font-size: 14px;
  font-weight: 800;
  font-family: AileronRegular;
  padding: 8px 32px;
  background: var(--accent-color);
  color: white;
  border-radius: 0px;
  clip-path: polygon(7% 0%, 100% 0%, 93% 100%, 0% 100%);
}

.aboutus {
  min-height: calc(100vh - 100px);
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.applynow-nav:hover {
  color: white;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.2);
}

.activenav .applynow-nav:hover {
  background: #fe5fa1cb;
  color: white;
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

button:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}

footer {
  padding: 50px 0;
  justify-content: space-between;
}

footer .mainlogowrap {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.336);
  text-decoration: none;
  gap: 20px;
  align-self: center;
}

footer hr {
  width: 100%;
  height: 1px;
  border: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgb(120, 120, 122) 58%,
    rgba(255, 255, 255, 0) 100%
  );
}

footer .social-icons a {
  margin-inline: 10px;
}

.flexaudi {
  display: flex;
  align-items: center;
}

.contact-page .project {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.apply-page .project {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.contact-page,
.apply-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#bookcalll {
  width: 100%;
}

#bookcall {
  scroll-margin-top: 100px;
}

.bookcall h2 {
  margin: 0;
}

.aboutus {
  flex-direction: column;
}

.progress {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  max-width: 1450px;
}

.progress img {
  width: 80%;
  min-width: 250px;
  max-width: 80%;
  border-radius: 5px;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.4);
}

.progress-phase {
  flex: 1;
}
.progress-phase img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.results h1,
.results p {
  text-align: center;
}
.results p {
  margin-inline: auto;
  max-width: 720px;
}
.examples {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  max-width: 1500px;
  margin-inline: auto;
  width: 100%;
}
.example-wrap {
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.example-wrap img {
  max-width: 100%;
}
.example-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
@media (max-width: 1000px) {
  .examples {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 40px;
  }
  .progress {
    flex-direction: column;
  }

  .progress-phase {
    width: 100%;
  }

  .progress img {
    width: 100%;
    max-width: 100%;
  }

  .project,
  footer {
    width: calc(100% - 40px);
    padding: 50px 20px;
    flex-direction: column;
  }

  .nav-wrap {
    width: calc(100% - 20px);
    padding-inline: 10px;
    justify-content: flex-start;
  }

  .menu-button {
    padding: 10px;
    top: 13px;
  }

  .mainlogowrap {
    display: flex;
    align-items: center;
  }

  .social-icons {
    gap: 10px;
  }

  .home-top,
  .project {
    overflow-x: hidden;
  }

  .home-top {
    flex-direction: column;
    min-height: 100vh;
    height: auto;
  }

  .home-top .text {
    width: calc(100% - 40px);
    margin-inline: auto;
  }

  .abs-back {
    top: auto;
    bottom: 0;
    height: 24%;
    width: 100%;
  }

  .home-top img {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .scroll-arrow {
    display: none;
  }

  .title h1 {
    font-size: 32px;
    font-weight: 900;
    line-height: 50px;
  }
  .home-top h1 {
    font-size: 80px;
    margin: -20px;
  }
  .home-top p {
    margin-top: 40px;
  }
  .partners {
    flex-direction: column;
  }

  .applynow {
    margin-inline: auto;
    width: 100%;
  }

  .mobi-cent a {
    width: 90%;
  }

  .mobi-cent {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .home-top .text {
    margin-top: 120px;
  }

  .title p {
    max-width: 100%;
  }

  .cards {
    flex-direction: column;
    gap: 10px;
  }

  .function {
    flex-direction: column-reverse;
    gap: 0px;
    margin-bottom: 50px;
  }

  .function:nth-of-type(even) {
    flex-direction: column-reverse;
  }

  .lottie-mobile {
    max-width: 95% !important;
    height: auto !important;
  }

  .function-text h3 {
    margin: 0;
  }

  .why-us-trust {
    z-index: 2;
    margin-top: 0;
    top: -150px;
    position: relative;
    margin-bottom: -150px;
  }

  .ovmob {
    overflow: visible;
  }

  .why-us-stats {
    flex-direction: column;
    gap: 50px;
  }

  .swiper {
    margin-left: 40px !important;
    height: 530px;
  }

  .swiper-slide {
    width: 95% !important;
  }

  .swiper-slide p {
    max-width: 90%;
  }

  footer {
    padding-block: 0;
  }
  .team {
    flex-direction: column;
    gap: 40px;
  }
  .team-member {
    width: 80%;
  }
  .full-potential-cont {
    min-width: auto;
    width: calc(100% - 20px);
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 10px;
  }
  .full-potential-cont h3 {
    font-size: 20px;
  }
  .full-potential-cont h2 {
    font-size: 50px;
  }
  footer .project {
    flex-direction: column;
    padding: 40px 0;
  }

  .contact-form {
    border-radius: 10px;
    width: 100%;
    min-width: auto;
    padding: 10px;
    padding-block: 20px;
  }

  .social-icons {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
  }

  .social-icons img {
    width: 20px;
    height: 20px;
  }

  .early-access-wrap .applynow {
    padding: 10px 0px;
    width: 90%;
  }

  .early-access-wrap .project {
    flex-direction: column-reverse;
    gap: 0px;
  }

  .lottieearly {
    max-width: 80%;
    height: auto;
  }

  .early-input {
    width: 80%;
  }

  .socials {
    gap: 10px;
  }

  .contact-page .project {
    flex-direction: column;
    gap: 0;
  }
}
